import { Flex, Text } from '@lego/klik-ui';
import { FC } from 'react';

interface ResultProps {
  value: number;
}

const Result: FC<ResultProps> = ({ value }) => (
  <Flex>
    <Text fontSize="2xl">Predicted T-Code: { value }</Text>
  </Flex>
);

export default Result;
