import { Box, LEGOLogo, Navbar, Spacer, Text, VerticalStack } from '@lego/klik-ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Header: FC = () => (
  <VerticalStack alignItems="stretch" spacing={ 0 }>
    <Navbar>
      <Navbar.Brand as={ Link } to="/">
        <LEGOLogo mr="24px"/>
        <Text>Shrinkage Analysis (FBZ)</Text>
      </Navbar.Brand>
      <Spacer/>
    </Navbar>
    <Box
      bg="salmon"
      textAlign="center"
      color="white"
      p={ 4 }
    >
      Remember that this is a best guess from a machine learning model - it is not necessarily the truth!
      If you find any results suspicious, do double check.
    </Box>
  </VerticalStack>
);
