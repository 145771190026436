import ShrinkagePredictionInputContract from './shrinkage-prediction-input-contract';

export interface ShrinkagePredictionInputModelValues {
  rawMaterial: number;
  boxLayout: number;
  holdingPressure: number;
  pressureMethod: number;
  elementArea: number;
  gateOpening: number;
  regeneratePercentage: number;
  height: number;
  length: number;
  mouldType: number;
  boxConcept: number;
  width: number;
  residenceTime: number;
}

export default class ShrinkagePredictionInputModel implements ShrinkagePredictionInputModelValues {
  rawMaterial!: number;
  boxLayout!: number;
  holdingPressure!: number;
  pressureMethod!: number;
  elementArea!: number;
  gateOpening!: number;
  regeneratePercentage!: number;
  height!: number;
  length!: number;
  mouldType!: number;
  boxConcept!: number;
  width!: number;
  residenceTime!: number;

  constructor(values: ShrinkagePredictionInputModelValues) {
    Object.assign(this, values);
  }

  toContract(): ShrinkagePredictionInputContract {
    return {
      raw_material: this.rawMaterial,
      box_layout: this.boxLayout,
      holding_pressure: this.holdingPressure,
      pressure_method: this.pressureMethod,
      element_area: this.elementArea,
      gate_opening: this.gateOpening,
      regenerate_pct: this.regeneratePercentage,
      height: this.height,
      length: this.length,
      mould_type: this.mouldType,
      box_concept: this.boxConcept,
      width: this.width,
      residence_time: this.residenceTime,
    };
  }
}
