import { PublicClientApplication } from '@azure/msal-browser';
import { KlikProvider } from '@lego/klik-ui';
import React, { FC, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { msalConfig } from './auth-config';
import { Layout } from './pages/Layout';
import { ShrinkagePrediction } from './pages/ShrinkagePrediction';
import AuthProvider from './providers/AuthProvider';
import SWRProvider from './providers/SWRProvider';

const App: FC = () => {
  const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);

  return (
    <AuthProvider msalInstance={ msalInstance }>
      <KlikProvider includeFont>
        <SWRProvider msalInstance={ msalInstance }>
          <Router>
            <Routes>
              <Route path="/" element={ <Layout/> }>
                <Route index element={ <ShrinkagePrediction/> }/>
              </Route>
            </Routes>
          </Router>
        </SWRProvider>
      </KlikProvider>
    </AuthProvider>
  );
};

export default App;
