import { Container } from '@lego/klik-ui';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';

export const Layout: FC = () => (
  <>
    <Header/>
    <Container maxW="full" my="5">
      <Outlet/>
    </Container>
  </>
);
