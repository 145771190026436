import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

// For React 18
// const root = createRoot(
//   document.getElementById("root") as HTMLElement
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
