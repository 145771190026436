import { Button, Divider, Flex, FormControl, HorizontalStack, Input, Text, VerticalStack } from '@lego/klik-ui';
import { Search as SearchIcon } from '@lego/klik-ui/icons';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ShrinkagePredictionInputModel, { ShrinkagePredictionInputModelValues } from '../../models/shrinkage-prediction-input-model';
import DropdownControl from './DropdownControl';

interface ShrinkagePredictionFormProps {
  isLoading: boolean;
  onSubmit: (model: ShrinkagePredictionInputModel) => void;
}

const ShrinkagePredictionForm: FC<ShrinkagePredictionFormProps> = ({ isLoading, onSubmit }) => {
  const form = useForm<ShrinkagePredictionInputModelValues>();
  const register = useCallback((fieldName: keyof ShrinkagePredictionInputModelValues) => form.register(fieldName, { required: true, valueAsNumber: true }), [form.register]);

  const onFormSubmit = useCallback((values) => {
    onSubmit(new ShrinkagePredictionInputModel(values));
  }, [onSubmit]);

  return (
    <FormProvider { ...form }>
      <form onSubmit={ form.handleSubmit(onFormSubmit) }>
        <VerticalStack alignItems={ 'stretch' } css={ {
          padding: '10px',
        } }>
          <FormControl isRequired>
            <FormControl.Label>Raw material</FormControl.Label>
            <DropdownControl fieldName="rawMaterial" placeholder="Select raw material">
              <option value="4.9045676470588235">TP</option>
              <option value="4.900984047530139">ABS</option>
              <option value="4.893285714285714">PC</option>
              <option value="4.8946432692307695">MABS LH</option>
              <option value="4.883625">HV-PC</option>
              <option value="4.895225819672131">HV-ABS</option>
              <option value="4.834166666666667">HV-POM</option>
            </DropdownControl>
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label>Box layout</FormControl.Label>
            <DropdownControl fieldName="boxLayout" placeholder="Select box layout">
              <option value="4.903115773801588">MM31 (27631) medium</option>
              <option value="4.899433760683761">MM30 (27630) small</option>
              <option value="4.893826099541666">LEGO STANDARD MOULD BOX 2</option>
              <option value="4.901134012232757">MM34 (27634) large</option>
              <option value="4.893048571428572">LEGO STANDARD MOULD BOX 3</option>
              <option value="4.896957446808511">STM3 - LEGO Standard Mould 3</option>
              <option value="4.906687068965517">STM2 - LEGO Standard Mould 2</option>
              <option value="4.893919042968749">STM-M - LEGO Standard Mould -Modify</option>
              <option value="4.902516666666666">MM 2 (27618)</option>
            </DropdownControl>
          </FormControl>
          <HorizontalStack>
            <FormControl flexGrow={ 1 } isRequired>
              <FormControl.Label>Holding pressure</FormControl.Label>
              <Input type="number" placeholder="Enter holding pressure (N/kg)" { ...register('holdingPressure') }></Input>
            </FormControl>
            <FormControl flexGrow={ 1 } isRequired>
              <FormControl.Label>Pressure method</FormControl.Label>
              <DropdownControl fieldName="pressureMethod" placeholder="Select pressure method">
                <option value="4.891838141025641">Interp. Secondary over point</option>
                <option value="4.899675961538461">PE method</option>
                <option value="4.899814430745945">Secondary over point</option>
              </DropdownControl>
            </FormControl>
          </HorizontalStack>
          <FormControl isRequired>
            <FormControl.Label>Element area</FormControl.Label>
            <Input type="number" placeholder="Enter element area (mm²)" { ...register('elementArea') }></Input>
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label>Gate opening</FormControl.Label>
            <Input type="number" placeholder="Enter gate opening (mm)" { ...register('gateOpening') }></Input>
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label>Regenerate percentage</FormControl.Label>
            <Input type="number" placeholder="Enter regenerate percentage" { ...register('regeneratePercentage') }></Input>
          </FormControl>
          <Divider/>
          <VerticalStack>
            <Text fontSize="l" fontWeight="semibold">Element dimensions</Text>
            <HorizontalStack>
              <FormControl flexGrow={ 1 } isRequired>
                <FormControl.Label justifyContent="center">Height</FormControl.Label>
                <Input type="number" placeholder="Enter height (mm)" { ...register('height') }></Input>
              </FormControl>
              <FormControl flexGrow={ 1 } isRequired>
                <FormControl.Label justifyContent="center">Width</FormControl.Label>
                <Input type="number" placeholder="Enter width (mm)" { ...register('width') }></Input>
              </FormControl>
              <FormControl flexGrow={ 1 } isRequired>
                <FormControl.Label justifyContent="center">Length</FormControl.Label>
                <Input type="number" placeholder="Enter length (mm)" { ...register('length') }></Input>
              </FormControl>
            </HorizontalStack>
          </VerticalStack>
          <Divider/>
          <FormControl isRequired>
            <FormControl.Label>Mould type</FormControl.Label>
            <DropdownControl fieldName="mouldType" placeholder="Select mould type">
              <option value="4.899182224731646">3-Plates</option>
              <option value="4.892092307692308">Tunnel inlet</option>
            </DropdownControl>
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label>Box concept</FormControl.Label>
            <DropdownControl fieldName="boxConcept" placeholder="Select box concept">
              <option value="4.9009211910585355">MP2015 V2</option>
              <option value="4.901262244725352">MP2015 V1</option>
              <option value="4.9074833333333325">MP2015 V2-JIA</option>
              <option value="4.897219800968748">LEGO Standard Mould</option>
              <option value="4.900142567567568">Child Hard Mould</option>
            </DropdownControl>
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label>Residence time</FormControl.Label>
            <Input type="number" placeholder="Enter residence time (seconds)" { ...register('residenceTime') }></Input>
          </FormControl>
          <Flex justifyContent="center">
            <Button rightIcon={ <SearchIcon/> } variant="outline" type="submit" isLoading={ isLoading }>
              Get result
            </Button>
          </Flex>
        </VerticalStack>
      </form>
    </FormProvider>
  );
};

export default ShrinkagePredictionForm;
