import { useToast } from '@lego/klik-ui';
import { ErrorToast } from '../components/ErrorToast';
import { ProblemJsonError } from '../models/problem-json-error';

export const useErrorToast = () => {
  const toast = useToast();

  return (error: ProblemJsonError, onDetails?: () => void) => {
    toast({
      position: 'top-right',
      duration: 5000,
      render: ({ onClose }) =>
        ErrorToast({
          error: error,
          onDetails: onDetails,
          onClose: onClose,
        }),
    });
  };
};
