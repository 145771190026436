import { CloseButton, InlineNotification, Link } from '@lego/klik-ui';
import { FC } from 'react';
import { ProblemJsonError } from '../models/problem-json-error';

export const ErrorToast: FC<{
  error: ProblemJsonError;
  onDetails?: () => void;
  onClose: () => void;
}> = (props) => {
  return (
    <InlineNotification variant="error">
      <InlineNotification.Content alignItems="flex-start" flexDirection="column">
        <InlineNotification.Title>{ props.error.problemJson.title }</InlineNotification.Title>
        <InlineNotification.Description>
          { props.error.problemJson.status } - { props.error.problemJson.detail }
        </InlineNotification.Description>
        { props.onDetails ? (
          <InlineNotification.Description>
            <Link onClick={ props.onDetails }>Details</Link>
          </InlineNotification.Description>
        ) : (
          <></>
        ) }
      </InlineNotification.Content>
      <CloseButton aria-label="" onClick={ props.onClose }/>
    </InlineNotification>
  );
};
