import { Dropdown, IDropdownProps } from '@lego/klik-ui';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ShrinkagePredictionInputModelValues } from '../../models/shrinkage-prediction-input-model';

interface DropdownProps {
  fieldName: keyof ShrinkagePredictionInputModelValues;
  placeholder?: string;
  children: IDropdownProps['children'];
}

const DropdownControl: FC<DropdownProps> = ({ fieldName, placeholder, children }) => {
  const { control } = useFormContext<ShrinkagePredictionInputModelValues>();

  return (
    <Controller
      name={ fieldName }
      control={ control }
      rules={ { required: true } }
      render={ ({ field: { value, onChange, ...field } }) => <Dropdown
        { ...field }
        value={ [`${ value }`] }
        onChange={ event => {
          if (!(event instanceof Array<string>)) {
            onChange(Number(event.target.value));
          }
        } }
        isNative
        placeholder={ placeholder }
      >
        { children }
      </Dropdown> }
    />
  );
};

export default DropdownControl;
