import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';

interface ActiveAccountProviderProps {
  children: ReactNode;
}

interface AuthProviderProps {
  msalInstance: PublicClientApplication;
}

const ActiveAccountProvider: FC<ActiveAccountProviderProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [activeAccount, setActiveAccount] = useState(instance.getActiveAccount());

  useEffect(() => {
    if (!activeAccount && accounts.length) {
      const firstAccount = accounts[0];
      instance.setActiveAccount(firstAccount);
      setActiveAccount(firstAccount);
    }
  }, [activeAccount, accounts, instance]);

  return activeAccount ? <>{children}</> : null;
};

const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({ msalInstance, children }) => (
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ActiveAccountProvider>{children}</ActiveAccountProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

export default AuthProvider;
