import { useMemo } from 'react';
import { BareFetcher, useSWRConfig } from 'swr';

type MutatingHttpMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const contentTypes: Partial<Record<MutatingHttpMethod, string>> = {
  POST: 'application/json',
  PUT: 'application/json',
  PATCH: 'application/merge-patch+json',
};

type ExtraArg<T> = { arg: T };

type MutationFetcher<TGetEntity, TPostEntity> = (
  url: string,
  extraArg: ExtraArg<TPostEntity>
) => TGetEntity | Promise<TGetEntity>;

const useMutationFetcher = <TGetEntity, TPostEntity>(
  method: MutatingHttpMethod,
  bodyAsQuery = false
): MutationFetcher<TGetEntity, TPostEntity> => {
  const { fetcher } = useSWRConfig() as { fetcher: BareFetcher<TGetEntity> };

  const options = useMemo(() => {
    const baseOptions: RequestInit = { method };
    const contentType = contentTypes[method];

    if (contentType) {
      baseOptions.headers = {
        'Content-Type': contentType,
      };
    }

    return baseOptions;
  }, [method]);

  return (url, { arg: body }) => {
    const urlWithQuery = bodyAsQuery ? `${url}?q=${JSON.stringify(body)}` : url;

    return fetcher(urlWithQuery, {
      ...options,
      body: bodyAsQuery ? undefined : JSON.stringify(body),
    });
  };
};

export default useMutationFetcher;
