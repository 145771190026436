import { IPublicClientApplication } from '@azure/msal-browser';
import { Button, ButtonGroup, Modal, Text, useDisclosure } from '@lego/klik-ui';
import { FC, ReactNode, useMemo, useState } from 'react';
import { BareFetcher, SWRConfig } from 'swr';
import { API_ROOT } from '../constants';
import { useErrorToast } from '../hooks/use-error-toast';
import { ProblemJson } from '../models/problem-json';
import { ProblemJsonError } from '../models/problem-json-error';

const fetcherWithApiAuth = <T, >(msalInstance: IPublicClientApplication): BareFetcher<T> =>
  async (path: string, init?: RequestInit): Promise<T> => {
    let requestInit = init;

    if (path.startsWith(API_ROOT)) {
      const accessToken = await msalInstance.acquireTokenSilent({ scopes: ['c11d58f7-5326-461f-9ac1-bb99eb1de7f8/ShrinkageAnalysis.Invoke'] });

      requestInit = {
        ...requestInit,
        headers: {
          ...requestInit?.headers,
          Authorization: `Bearer ${ accessToken.accessToken }`,
        },
      };
    }

    const response = await fetch(path, requestInit);

    if (response.status === 204) {
      return undefined as unknown as T;
    }

    const responseData = (await response.json()) as T | ProblemJson;

    if (!response.ok) {
      throw new ProblemJsonError(`Error returned from ${ path }`, responseData as ProblemJson);
    }

    return responseData as T;
  };

interface SWRProviderProps {
  msalInstance: IPublicClientApplication;
  children: ReactNode;
}

const SWRProvider: FC<SWRProviderProps> = ({ msalInstance, children }) => {
  const errorToast = useErrorToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState<ProblemJsonError | undefined>();
  const fetcher = useMemo(() => fetcherWithApiAuth(msalInstance), [msalInstance]);

  return (
    <SWRConfig
      value={ {
        fetcher,
        onError: (swrError) => {
          if (swrError instanceof ProblemJsonError) {
            errorToast(swrError, onOpen);
            setError(swrError);
          }
        },
      } }
    >
      { children }
      { error ? (
        <Modal isOpen={ isOpen } onClose={ onClose } size="6xl" status="error" closeOnOverlayClick>
          <Modal.Overlay/>
          <Modal.Content>
            <Modal.Header>
              { error.problemJson.status } - { error.problemJson.detail ?? error.problemJson.title }
            </Modal.Header>
            <Modal.CloseButton/>
            <Modal.Body>
              <Text display="block" fontFamily="monospace" whiteSpace="pre">
                { JSON.stringify(error.problemJson.exceptionDetails, undefined, 2) }
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup>
                <Button onClick={ onClose }>Close</Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      ) : undefined }
    </SWRConfig>
  );
};

export default SWRProvider;
