import { Container, VerticalStack } from '@lego/klik-ui';
import { FC, useCallback } from 'react';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';
import Result from '../components/Result';
import ShrinkagePredictionForm from '../components/shrinkage-prediction-form/ShrinkagePredictionForm';
import { SHRINKAGE_ANALYSIS_URL } from '../constants';
import useMutationFetcher from '../hooks/use-mutation-fetcher';
import { ProblemJsonError } from '../models/problem-json-error';
import ShrinkagePredictionInputContract from '../models/shrinkage-prediction-input-contract';
import ShrinkagePredictionInputModel from '../models/shrinkage-prediction-input-model';

const useShrinkageAnalysisModel = (): SWRMutationResponse<number[], ProblemJsonError, ShrinkagePredictionInputContract, string> => {
  const fetcher = useMutationFetcher<number[], ShrinkagePredictionInputContract>('POST');

  return useSWRMutation<number[], ProblemJsonError, string, ShrinkagePredictionInputContract>(SHRINKAGE_ANALYSIS_URL, fetcher);
};

export const ShrinkagePrediction: FC = () => {
  const { trigger, isMutating, data } = useShrinkageAnalysisModel();
  const onFormSubmit = useCallback(async (model: ShrinkagePredictionInputModel) => {
    await trigger(model.toContract());
  }, [trigger]);

  return (
    <VerticalStack>
      <Container maxW="container.sm" bg="papayawhip" mb="10">
        <ShrinkagePredictionForm isLoading={ isMutating } onSubmit={ onFormSubmit }/>
      </Container>
      { data && <Result value={ data[0] }/> }
    </VerticalStack>
  );
};
