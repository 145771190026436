import { Configuration, LogLevel } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: 'c11d58f7-5326-461f-9ac1-bb99eb1de7f8',
    authority: 'https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa',
    redirectUri: '/',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);

            return;
          case LogLevel.Info:
            console.info(message);

            return;
          case LogLevel.Verbose:
            console.debug(message);

            return;
          case LogLevel.Warning:
            console.warn(message);

            return;
          default:
            return;
        }
      },
    },
  },
};
